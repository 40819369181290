import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useMulticallContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/eggPriceContracts'

type ApiResponse = {
  prices: {
    [key: string]: string
  }
  update_at: string
}

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/panKronosDao Swap/gatsby-panrim-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
const api = 'https://api.panKronosDao Swap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){
          // rim: string; wBNB: string; rimBNBLP: string; usdt: string; bnbBUSDLP: string
          const {rim, wBNB, rimBNBLP,usdt,bnbBUSDLP} = priceContracts;
          const calls = [
            [rim, ERC20_INTERFACE.encodeFunctionData("balanceOf", [rimBNBLP])],
            [wBNB, ERC20_INTERFACE.encodeFunctionData("balanceOf", [rimBNBLP])],


            [wBNB, ERC20_INTERFACE.encodeFunctionData("balanceOf", [bnbBUSDLP])],
            [usdt, ERC20_INTERFACE.encodeFunctionData("balanceOf", [bnbBUSDLP])],

          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [rimAmountInLP, wBNBAmountInRIMLP,
          
            wBNBAmountInUSDTLP,
            usdtAmountInBNBLP
          ] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const _rimAmountInLP = new BigNumber(rimAmountInLP);
          const _wBNBAmountInRIMLP = new BigNumber(wBNBAmountInRIMLP);
          const rimPriceInBNB = _wBNBAmountInRIMLP.div(1e18).div(_rimAmountInLP.div(1e18))



          const _wBNBAmountInUSDTLP = new BigNumber(wBNBAmountInUSDTLP);
          const _usdtAmountInBNBLP = new BigNumber(usdtAmountInBNBLP);
          const bnbPrice = (_usdtAmountInBNBLP.div(1e6) ).div(_wBNBAmountInUSDTLP.div(1e18)).toNumber();

          console.log("rimPriceInBNB",rimPriceInBNB.toString())

          setData(rimPriceInBNB.times(bnbPrice).toNumber())
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}

export default useGetPriceData
